import { Routes, Route } from "react-router-dom";
import React, { Suspense, lazy, useEffect } from "react";
import Loader from "./Components/Loader/Loader";
import Subscribe from "./Components/Subscriber/Subscribe";
const LazyLogin = lazy(() => import("./Components/Login"));
const Dashboard = lazy(() => import("./Components/Dashboard"));
const PartnersManagement = lazy(() =>
  import("./Components/PartnersManagement/PartnersManagement")
);
const ForgotPassword = lazy(() => import("./Components/ForgotPassword"));
const Profile = lazy(() => import("./Components/Profile"));
const ResetPassword = lazy(() => import("./Components/ResetPassword"));
const EditProfile = lazy(() => import("./Components/EditProfile"));
const ChangePassword = lazy(() => import("./Components/ChangePassword"));
const SportsVenue = lazy(()=>import('./Components/SportsManagement/SportsVenue'))
const CourtManagement = lazy(()=>import('./Components/Courts/CourtManagement'))
const Amenities = lazy(()=>import('./Components/Amenities/Amenties'))
const ContentManagement = lazy(()=>import('./Components/Content-Management/ContentManagement'))
const EventListing = lazy(()=>import('./Components/Events/EventList'))
const TransactionManagement = lazy(()=>import('./Components/Transaction-Management/TransactionManagement'))
const HelpSupport = lazy(()=>import('./Components/Support/HelpSupport'))
const ServiceBooking = lazy(()=>import('./Components/ServiceBooking/ServiceBooking'))
const FAQ = lazy(()=>import('./Components/Faq/FAQ'))
const ReportManagement = lazy(()=>import('./Components/ReportManagement/ReportManagement'))
const UserContentManagement = lazy(()=>import('./Components/Content-Management/UserContentManagement'))
const Contacts = lazy(()=>import('./Components/Contacts/Contacts'))
const Transactions = lazy(()=>import('./Components/Transaction/Transactions'))
const SlotManagement = lazy(()=>import('./Components/Slots/SlotManagement'))
const Feedback = lazy(()=>import("./Components/Feedback/Feedback"))
const ActivityLogs = lazy(()=>import('./Components/ActivityLogs/ActivityLogs'))
function App() {
  return (
    <>
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route
            path="*"
            element={
              <Suspense fallback={<Loader />}>
                <LazyLogin />
              </Suspense>
            }
          />
          <Route
            path="/forgot-password"
            element={
              <Suspense fallback={<Loader />}>
                <ForgotPassword />
              </Suspense>
            }
          />
          <Route
            path="/edit-profile"
            element={
              <Suspense fallback={<Loader />}>
                <EditProfile />
              </Suspense>
            }
          />
          <Route
            path="/change-password"
            element={
              <Suspense fallback={<Loader />}>
                <ChangePassword />
              </Suspense>
            }
          />

          <Route
            path="/dashboard"
            element={
              <Suspense fallback={<Loader />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path="/profile"
            element={
              <Suspense fallback={<Loader />}>
                <Profile />
              </Suspense>
            }
          />
          <Route
            path="/reset-password"
            element={
              <Suspense fallback={<Loader />}>
                <ResetPassword />
              </Suspense>
            }
          />

          <Route
            path="/partners-management"
            element={
              <Suspense fallback={<Loader />}>
                <PartnersManagement />
              </Suspense>
            }
          />
           <Route
            path="/transaction-management"
            element={
              <Suspense fallback={<Loader />}>
                <Transactions />
              </Suspense>
            }
          />
                    <Route
            path="/user-content-management"
            element={
              <Suspense fallback={<Loader />}>
                <UserContentManagement />
              </Suspense>
            }
          />

                    <Route
            path="/report-management"
            element={
              <Suspense fallback={<Loader />}>
                <ReportManagement />
              </Suspense>
            }
          />

          <Route
            path="/sports-venue/:id"
            element={
              <Suspense fallback={<Loader />}>
                <SportsVenue />
              </Suspense>
            }
          />
                    <Route
            path="/amenities"
            element={
              <Suspense fallback={<Loader />}>
                <Amenities />
              </Suspense>
            }
          />

          <Route
            path="/court-management/:id"
            element={
              <Suspense fallback={<Loader />}>
                <CourtManagement />
              </Suspense>
            }
          />
          <Route
            path="/content-management"
            element={
              <Suspense fallback={<Loader />}>
                <ContentManagement />
              </Suspense>
            }
          />
                    <Route
            path="/event-management"
            element={
              <Suspense fallback={<Loader />}>
                <EventListing />
              </Suspense>
            }
          />
              <Route
            path="/faq"
            element={
              <Suspense fallback={<Loader />}>
                <FAQ />
              </Suspense>
            }
          />
                    <Route
            path="/booking-management"
            element={
              <Suspense fallback={<Loader />}>
                <TransactionManagement />
              </Suspense>
            }
          />
                    <Route
            path="/help-support"
            element={
              <Suspense fallback={<Loader />}>
                <HelpSupport />
              </Suspense>
            }
          />
                    <Route
            path="/service-booking"
            element={
              <Suspense fallback={<Loader />}>
                <ServiceBooking />
              </Suspense>
            }
          />
<Route
            path="/subscribe"
            element={
              <Suspense fallback={<Loader />}>
                <Subscribe />
              </Suspense>
            }
          />
          <Route
            path="/slot-management"
            element={
              <Suspense fallback={<Loader />}>
                <SlotManagement />
              </Suspense>
            }
          />
           <Route
            path="/activity-logs"
            element={
              <Suspense fallback={<Loader />}>
                <ActivityLogs />
              </Suspense>
            }
          />
          <Route
            path="/contacts"
            element={
              <Suspense fallback={<Loader />}>
                <Contacts />
              </Suspense>
            }
          />
                    <Route
            path="/feedback"
            element={
              <Suspense fallback={<Loader />}>
                <Feedback />
              </Suspense>
            }
          />

        </Routes>
      </Suspense>
    </>
  );
}

export default App;
