import React from 'react';
import Lottie from 'lottie-react';
import animationData from './Animation - 1711607980822.json'; // Replace 'your_animation.json' with the path to your Lottie animation JSON file

const Loader = () => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100vw', height: '100vh' }}>
      <div style={{ width: 200, height: 200 }}> {/* Adjust width and height as needed */}
        <Lottie animationData={animationData} autoplay loop />
      </div>
    </div>
  );
};

export default Loader;
