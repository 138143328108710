import React, { useEffect, useState } from "react";
import Header from "../Header";
import MiddleHeader from "../MiddleHeader";
import {
  GetPartner,
  GetSubscribers,
  GetViewPartner,
  PartnerStatus,
  PostPartners,
} from "../../httpServices/dashboardHttpServices/dashboardHttpServices";
import { MDBDataTable, MDBDataTableV5 } from "mdbreact"; // Import MDBDataTableV5
import moment from "moment";
import { useForm } from "react-hook-form";
import Swal from "sweetalert2";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useUserAuth } from "../../httpServices/AuthContext";
import { Button } from "rsuite";
import Loader from "../Loader/Loader";

const Subscribe = () => {
  const [partners, setPartners] = useState([]);
  const [filteredPartners, setFilteredPartners] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(false); // Add loading state
  const [editId, setEditId] = useState(0)
  const navigate = useNavigate();
  const location = useLocation();

  const { setpartnerVenue } = useUserAuth();
  const token = localStorage.getItem("captain_token");
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  if (!token) {
    navigate("/");
  }

  const fetchPartner = async () => {
    try {
      setLoading(true)
      const response = await GetSubscribers();
      console.log(response);
      setPartners(response?.data?.results?.subscribers);
      setLoading(false)
    } catch (error) {
      console.log(error.message);
    }
  };

  useEffect(() => {
    // Filter partners based on search term whenever it changes
    const filtered = partners.filter((partner) => {
      return (
        partner.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        // partner.email.toLowerCase().includes(searchTerm.toLowerCase()) ||
        // partner.phone_number.includes(searchTerm.toLowerCase()) ||
        moment(partner.createdAt).format("L").includes(searchTerm.toLowerCase())
      );
    });
    setFilteredPartners(filtered);
  }, [searchTerm, partners]);
  
  useEffect(() => {
    fetchPartner();
  }, []);

  // Define DataTable columns
  if(loading){
    return <Loader/>
  }
  const columns = [
    {
      label: (
        <div className="d-flex align-items-center">
          <span>S.no</span>
          <i className="bi bi-arrow-down-up ms-1"></i>
        </div>
      ),
      field: "sno",
      sort: "asc",
      width: 300,
    },
    {
      label: (
        <div className="d-flex align-items-center">
          <span>Email</span>
          <i className="bi bi-arrow-down-up ms-1"></i>
        </div>
      ),
      field: "email",
      sort: "asc",
      width: 300,
    },
    {
      label: (
        <div className="d-flex align-items-center">
          <span>Subscribed Date</span>
          <i className="bi bi-arrow-down-up ms-1"></i>
        </div>
      ),
      field: "created_date",
      sort: "asc",
      width: 300,
    },
   
  ];
  // Map partners data to DataTable rows
  const rows = filteredPartners.map((partner, index) => ({
    sno: index+1,
    email: partner.email,
    created_date: moment(partner.createdAt).format("L"),
  }));
 

  const onSubmit = async (data, e) => {
    e.preventDefault(); // Prevent default form submission
    setLoading(true); // Set loading to true
    
    try {
      const response = await PostPartners(data);
      
      if (!response?.data?.error) {
        Swal.fire({
          toast: true,
          icon: "success",
          position: "top-end",
          title: "Successfully Added Partner",
          showConfirmButton: false,
          timerProgressBar: true,
          timer: 3000,
        });
        document.getElementById("cncl").click();
        reset();
        fetchPartner();
      } 
    } catch (error) {
      reset();
      Swal.fire({
        toast: true,
        icon: "error",
        position: "top-end",
        title: error.response?.data?.message || "An error occurred",
        showConfirmButton: false,
        timerProgressBar: true,
        timer: 3000,
      });
    } finally {
      reset();
      setLoading(false); // Set loading to false after the request is completed
    }
  };
  

  return (
    <>
      <div class="superadmin" >
        <Header />

        <div className="superadmin_main " >
          <div className="header_part">
            <div className="breadcrumb_part">
              <nav aria-label="breadcrumb">
                <ol className="breadcrumb mb-0">
                  <li className="breadcrumb-item">
                    <Link
                      style={{
                        color:
                          location.pathname === "/subscribe"
                            ? "blue"
                            : "black",
                      }}
                      to="/subscribe"
                    >
                      Subscribers
                    </Link>
                  </li>

                  <li className="breadcrumb-item active" aria-current="page" />
                </ol>
              </nav>
            </div>
            <div className="profile_part">
              <div className="header_notification">
                <div className="dropdown">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {/* <img src="assets/img/bell.png" alt /> */}
                  </button>
                  {/* <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                     <li><a class="dropdown-item" href="#">Action</a></li>
                     <li><a class="dropdown-item" href="#">Another action</a></li>
                     <li><a class="dropdown-item" href="#">Something else here</a></li>
                     </ul> */}
                </div>
              </div>
              <MiddleHeader />
            </div>
          </div>
          <div className="superadmin_inner">
          <div className="position-relative">
            <br />

            <div className="row">
              <div className="col-12 table_top mb-3">
                <div className="row align-items-center">
                  <div className="col table_head">
                  </div>
                  <div className="col-auto table_button mx-3">
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row mx-0 tabel_bar">
                  <div className="col d-flex align-items-center">
                    <div className="tabel_bar_nav">
                      {/* <a href="javascript:;">
                        <img src="/assets/img/nav1.png" alt />
                      </a>
                      <a href="javascript:;">
                        <img src="/assets/img/nav2.png" alt />
                      </a>
                      <a href="javascript:;">
                        <img src="/assets/img/nav3.png" alt />
                      </a> */}
                    </div>
                    {/* <div className="selectedd">
                      <span>2 Selected</span>
                    </div> */}
                  </div>
                  <div className="col-auto pe-2">
                    <form
                      action
                      className="search_bar"
                      onSubmit={(e) => e.preventDefault()}
                    >
                      <div className="form-group position-relative">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search"
                          style={{ color: "black" }}
                          value={searchTerm}
                          onChange={(e) => setSearchTerm(e.target.value)}
                        />
                        <img
                          className="search_button"
                          src="/assets/img/search.png"
                          alt=""
                        />
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-12 mdb_table mt-3 ">
                <div className="table-responsive">
                  <MDBDataTable
                    // bordered
                    displayEntries={false}
                    entries={10}
                    className="text-nowrap"
                    hover
                    searching={false}
                    data={{ columns: columns, rows: rows }}
                    // data={signatories}
                    noBottomColumns
                    // paging={signatories?.length < 5 ? true: false}
                    paginationLabel={"«»"}
                    sortable={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>
      </div>
      
    
      
    </>
  );
};

export default Subscribe;
