import { createContext, useContext, useState, useEffect } from "react";
import axios from "axios";
import { Navigate, useNavigate } from "react-router-dom";
import adminHttpServices from "./authServices/adminHttpServices";
import Swal from "sweetalert2";
const userAuthContext = createContext();

export function UserAuthContextProvider({ children }) {
  const [user, setUser] = useState([]);
  const [error, setError] = useState("");
  const [message, setMessage] = useState("");
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [formToShow, setFormToShow] = useState(true);
  const [partnerVenue, setpartnerVenue] = useState("");
  const [partnerCourt, setPartnercourt] = useState("")
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  const login = async (formData) => {
    setIsLoading(true);
    try {
      const { data } = await adminHttpServices.put(
        `${process.env.REACT_APP_API_URL}/api/admin/login`,
        formData
      );

      console.log(data);
      const token = data?.results?.token;
      const details = data?.results?.verify;
      if (token) {
        // If login is successful, set the token in local storage
        localStorage.setItem("captain_token", token);
        // You can add more logic here if needed, e.g., setUser, setIsLoggedIn, etc.

        // Display a success message using SweetAlert2
        Swal.fire({
          icon: "success",
          title: "Login Successful!",
          text: "Redirecting to dashboard...",
          timer: 1000, // Auto close the alert after 2 seconds
          showConfirmButton: false,
        }).then(() => {
          setUser(details);
          // Redirect to the dashboard or perform any other actions after the alert is closed
          navigate("/dashboard");
        });
      } else {
        // If login fails, display an error message using SweetAlert2
        Swal.fire({
          icon: "error",
          title: "Login Failed",
          text: data?.error?.message || "Invalid Credentials",
        });
      }
    } catch (error) {
      console.error("Error during API request:", error);
      // Handle other errors, e.g., show a general error message to the user
    } finally {
      setIsLoading(false);
    }
  };
  const logout = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will be logged out!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, log me out!",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        localStorage.removeItem("captain_token");
        setUser([]);
        setIsLoggedIn(false);
        navigate("/"); // or navigate to the homepage
        Swal.fire("Logged Out!", "", "success");
      }
    });
  };
  const forgotPassword = async (formData) => {
    setIsLoading(true);
    try {
      const { data } = await adminHttpServices.put(
        `${process.env.REACT_APP_API_URL}/api/admin/forgotPassword`,
        formData
      );
      console.log(data);

      // Check if password reset email was sent successfully
      if (data.error === false) {
        // Show error message using SweetAlert
        Swal.fire({
          icon: "success",
          title: "Password Reset Email Code Sent",
        }).then(() => {
          setFormToShow(false);
          // Redirect to the dashboard or perform any other actions after the alert is closed
          console.log();
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Email not Found",
          text:
            data && data.error
              ? data.error.message
              : "An error occurred while processing your request. Please try again later.",
        });
      }

      // If you need to perform additional actions based on the response, you can do it here
    } catch (error) {
      console.error("Error during API request:", error);
      // Show error message using SweetAlert if API request fails
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred while processing your request. Please try again later.",
      });
    } finally {
      setIsLoading(false);
    }
  };
  const verification = async (formData) => {
    setIsLoading(true);
    try {
      const { data } = await adminHttpServices.put(
        `${process.env.REACT_APP_API_URL}/api/admin/verifyOTP`,
        formData
      );
      console.log(data);
      const captain_token = data?.results?.token;
      localStorage.setItem("captain_token", captain_token);

      if (data.error) {
        // Show SweetAlert for invalid OTP
        Swal.fire({
          icon: "error",
          title: "Invalid OTP",
          text: "Please enter a valid OTP!",
        });
      } else {
        // Show SweetAlert for successful OTP verification
        Swal.fire({
          icon: "success",
          title: "OTP Verified Successfully",
          text: "You will be redirected to reset your password.",
        }).then(() => {
          navigate("/reset-password");
        });
      }
    } catch (error) {
      console.error("Error during verification:", error.message);
      // Show SweetAlert for error
      Swal.fire({
        icon: "error",
        title: "Error",
        text: "An error occurred during OTP verification. Please try again later.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const reset_password = async (formData) => {
    setIsLoading(true);
    try {
      const { data } = await adminHttpServices.put(
        `${process.env.REACT_APP_API_URL}/api/admin/resetPassword`,
        formData
      );
      console.log(data);
      if (data.error) {
        setError(error);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: error,
        });
      } else {
        // Password update successful
        Swal.fire({
          icon: "success",
          title: "Password Updated",
          text: "Your password has been successfully updated.",
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/");
          }
        });
      }
    } catch (error) {
      console.error("Error during API request:", error);
      // Handle other errors, e.g., show a general error message to the user
    } finally {
      setIsLoading(false);
    }
  };
  const captain_profile = async () => {
    setIsLoading(true);
    try {
      const { data } = await adminHttpServices.get(
        `${process.env.REACT_APP_API_URL}/api/admin/getAdminData`
      );
      setUser(data);
    } catch (error) {
      console.error("Error during API request:", error);
      // Handle other errors, e.g., show a general error message to the user
    } finally {
      setIsLoading(false);
    }
  };

  const change_password = async (formData) => {
    setIsLoading(true);
    try {
      const response = await adminHttpServices.put(
        `${process.env.REACT_APP_API_URL}/api/admin/changePassword`,
        formData
      );
      console.log(response?.data);
  
      if (!response?.data?.error) {
        // Display error message using SweetAlert if error flag is true
        Swal.fire({
          icon: "success",
          title: "Password Changed Successfully",
        });
        localStorage.removeItem("captain_token");
        setUser([]);
        setIsLoggedIn(false);
        navigate("/"); // or navigate to the homepage
        Swal.fire("Password Changed Successfully", "", "success");

      } else {
        Swal.fire({
          icon: "error",
          title: response?.data?.message,
        });

        // Display success message using SweetAlert if no error
      }
    } catch (error) {
      console.error("Error during API request:", error);
      // Display error message using SweetAlert for other errors
    } finally {
      setIsLoading(false);
    }
  };
  
  const edit_profile = async (formData) => {
    setIsLoading(true);
    try {
      const response = await adminHttpServices.put(
        `${process.env.REACT_APP_API_URL}/api/admin/updateProfile`,
        formData
      );
      console.log(response.data);

      if (response.data.error) {
        // Display error message using SweetAlert if error flag is true
        Swal.fire({
          icon: "error",
          title: response?.error.message,
        });
      } else {
        // Display success message using SweetAlert if no error
        Swal.fire({
          icon: "success",
          title: "Profile Edited Successfully",
        });
      }
    } catch (error) {
      console.error("Error during API request:", error);
      // Display error message using SweetAlert for other errors
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <userAuthContext.Provider
      value={{
        login,
        forgotPassword,
        verification,
        reset_password,
        captain_profile,
        user,
        change_password,
        partnerVenue,
        setpartnerVenue,
        logout,
        edit_profile,
        formToShow,
        setPartnercourt,
        partnerCourt,
        // logout, forgotPassword, verification, reset_password, change_password, request_again,message, user, error, isLoggedIn, isLoading
      }}
    >
      {children}
    </userAuthContext.Provider>
  );
}

export function useUserAuth() {
  return useContext(userAuthContext);
}
