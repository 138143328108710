import React, { useEffect } from 'react'
import { useUserAuth } from '../httpServices/AuthContext'
import { Link } from 'react-router-dom'

const MiddleHeader = () => {
    const {user,captain_profile,logout} = useUserAuth()
    useEffect(() => {
        const recall_func = async( )=>{
            await captain_profile()
        }
        recall_func()
    }, [])
    
  return (
    <>
    <div className="header_profile">
                <div className="dropdown">
                  <button
                    className="btn btn-secondary"
                    type="button"
                    id="dropdownMenuButton1"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    <div className="propic">
                      <img                     style={{
                      borderRadius: "50%",
                      objectFit: "cover",
                    }}
 src={user?.results?.admin?.image || "/assets/img/profile.png"} alt />
                    </div>
                    <span>{user?.results?.admin?.firstName} {user?.results?.admin?.lastName}</span>
                    <img src="/assets/img/arrow.png" alt />
                  </button>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuButton1"
                  >
                    <li>
                      <Link className="dropdown-item" to="/profile">
                        <img src="/assets/img/profilee.png" alt /> Profile
                      </Link>
                    </li>
                    <li>
                      <Link to='/change-password' className="dropdown-item">
                        <img src="/assets/img/password.png" alt />
                        Password Change
                      </Link>
                    </li>
                    <li>
                      <a className="dropdown-item" onClick={logout}>
                        <img src="/assets/img/logot.png" alt />
                        Logout
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
    </>
  )
}

export default MiddleHeader