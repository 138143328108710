import React, { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useUserAuth } from "../httpServices/AuthContext";
const Header = () => {
  const location = useLocation();
  const {logout} = useUserAuth()

  
  return (
    <>
      <div className="siderbar_part">
        <div className="logo_part">
          <Link to="/dashboard">
            <img src="/assets/img/logo.png" alt />
          </Link>
        </div>
        <div className="siderbar_menus">
          <h2>MAIN MENU</h2>
          <ul>
            <li>
              <Link
                className={location.pathname === "/dashboard" ? "active" : ""}
                to="/dashboard"
              >
                <div className="sicon">
                  <img src="/assets/img/dashboard.svg" alt />
                </div>
                Dashboard
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname.includes("/partners-management") ||
                  location.pathname.includes("/sports-venue/")
                    ? "active"
                    : "" || location.pathname.includes("/court-management/")
                    ? "active"
                    : ""
                }
                to="/partners-management"
              >
                <div className="sicon">
                  <img src="/img/deal.svg" alt />
                </div>
                <div className="ellipsis-1">Partners Management</div>
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname.includes("/amenities") ||
                  location.pathname.includes("/amenities/")
                    ? "active"
                    : "" || location.pathname.includes("/amenities/")
                    ? "active"
                    : ""
                }
                to="/amenities"
              >
                <div className="sicon">
                  <img src="/assets/img/company.svg" alt />
                </div>
                <div className="ellipsis-1">Amenities</div>
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname.includes("/event-management") ||
                  location.pathname.includes("/event-management/")
                    ? "active"
                    : "" || location.pathname.includes("/event-management/")
                    ? "active"
                    : ""
                }
                to="/event-management"
              >
                <div className="sicon">
                  <img src="/img/event.svg" alt />
                </div>
                <div className="ellipsis-1">Event Management</div>
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname.includes("/booking-management") ||
                  location.pathname.includes("/booking-management/")
                    ? "active"
                    : "" || location.pathname.includes("/booking-management/")
                    ? "active"
                    : ""
                }
                to="/booking-management"
              >
                <div className="sicon">
                <img src="/img/Booking.svg" alt style={{ fontWeight: 'bold' }} />
                                </div>
                <div className="ellipsis-1">Booking Management</div>
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname.includes("/report-management") ||
                  location.pathname.includes("/booking-management/")
                    ? "active"
                    : "" || location.pathname.includes("/report-management/")
                    ? "active"
                    : ""
                }
                to="/report-management"
              >
                <div className="sicon">
                <img src="/img/Report.svg" alt style={{ fontWeight: 'bold' }} />
                                </div>
                <div className="ellipsis-1">Report Management</div>
              </Link>
            </li>
          
            <li>
              <Link
                className={
                  location.pathname.includes("/content-management") ||
                  location.pathname.includes("/content-management/")
                    ? "active"
                    : "" || location.pathname.includes("/content-management/")
                    ? "active"
                    : ""
                }
                to="/content-management"
              >
                <div className="sicon">
                  <img src="/img/content.svg" alt />
                </div>
                <div className="ellipsis-1">Content Management</div>
              </Link>
            </li>
              
            <li>
              <Link
                className={
                  location.pathname.includes("/transaction-management") ||
                  location.pathname.includes("/transaction-management/")
                    ? "active"
                    : "" || location.pathname.includes("/transaction-management/")
                    ? "active"
                    : ""
                }
                to="/transaction-management"
              >
                <div className="sicon">
                  <img src="/img/transaction.svg" alt />
                </div>
                <div className="ellipsis-1">Transaction Management</div>
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname.includes("/activity-logs") ||
                  location.pathname.includes("/activity-logs/")
                    ? "active"
                    : "" || location.pathname.includes("/activity-logs/")
                    ? "active"
                    : ""
                }
                to="/activity-logs"
              >
                <div className="sicon">
                  <img src="/img/history.svg" alt />
                </div>
                <div className="ellipsis-1">Activity Logs</div>
              </Link>
            </li>
            {/* <li>
              <Link
                className={
                  location.pathname.includes("/feedback") ||
                  location.pathname.includes("/feedback/")
                    ? "active"
                    : "" || location.pathname.includes("/feedback/")
                    ? "active"
                    : ""
                }
                to="/feedback"
              >
                <div className="sicon">
                <img src="/assets/img/star.svg" alt /> 
                </div>
                <div className="ellipsis-1">Feedback Management</div>
              </Link>
            </li> */}

            <li>
              <Link
                className={
                  location.pathname.includes("/contacts") ||
                  location.pathname.includes("/contacts/")
                    ? "active"
                    : "" || location.pathname.includes("/contacts/")
                    ? "active"
                    : ""
                }
                to="/contacts"
              >
                <div className="sicon">
                <img src="/img/PhoneCall.svg" alt /> 
                </div>
                <div className="ellipsis-1">Contact Details </div>
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname.includes("/help-support") ||
                  location.pathname.includes("/help-support/")
                    ? "active"
                    : "" || location.pathname.includes("/help-support/")
                    ? "active"
                    : ""
                }
                to="/help-support"
              >
                <div className="sicon">
                  <img src="/img/support.svg" alt />
                </div>
                <div className="ellipsis-1">Help & Support</div>
              </Link>
            </li>
            <li>
              <Link
                className={
                  location.pathname.includes("/faq") ||
                  location.pathname.includes("/faq/")
                    ? "active"
                    : "" || location.pathname.includes("/faq/")
                    ? "active"
                    : ""
                }
                to="/faq"
              >
                <div className="sicon">
                  <img src="/img/FAQs.svg" alt />
                </div>
                <div className="ellipsis-1">Faq</div>
              </Link>
            </li>
            {/* <li>
              <Link
                className={
                  location.pathname.includes("/profile") ||
                  location.pathname.includes("/profile/") ||
                  location.pathname.includes("/edit-profile")
                    ? "active"
                    : "" || location.pathname.includes("/profile/")
                    ? "active"
                    : ""
                }
                to="/profile"
              >
                <div className="sicon">
                  <img src="/img/profile.png" alt />
                </div>
                <div className="ellipsis-1">My Profile</div>
              </Link>
            </li> */}
            <li>
              <Link
                className={
                  location.pathname.includes("/subscribe") ||
                  location.pathname.includes("/subscribe/") ||
                  location.pathname.includes("/subscribe")
                    ? "active"
                    : "" || location.pathname.includes("/subscribe/")
                    ? "active"
                    : ""
                }
                to="/subscribe"
              >
                <div className="sicon">
                  <img src="/img/subscribe.svg" alt />
                </div>
                <div className="ellipsis-1">Subscribers</div>
              </Link>
            </li>
            <li className="nav-item text-dark" >
            <a
              className="nav-link text-dark fs-5 align-middle cursor_pointer"
              aria-current="page"
              onClick={logout}
            >
              <img
                src="/img/logout.svg"
              
                className="align-middle sidebar-icon"
              />

              <span className="ms-3 align-middle sidebar-btn">Logout</span>
            </a>
          </li>

            {/* <li>
              <Link className={location.pathname === '/sports-venue' ? 'active' : ''} to="/sports-venue">
                <div className="sicon">
                  <img src="//assets/img/sports.svg" alt />
                </div>
                <div className="ellipsis-1">Sports Venue</div>
              </Link>
            </li> */}
          </ul>
        </div>
      </div>
    </>
  );
};

export default Header;
